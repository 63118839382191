import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { ClassNameProps } from '@ComponentProps';
import { convertPenceToPounds } from '@Core/prices/magnitudes';
import { CurrencyFormat, useCurrency } from '@Core/prices/useCurrency';

export interface StaticPriceProps extends ClassNameProps {
  /**
   * Styles to style the numeric (eg: £100) part of the price.
   */
  priceStyles?: SxStyleProp;
  /**
   * Styles to style the suffix (eg: pp) part of the price.
   */
  suffixStyles?: SxStyleProp;
  /**
   * styles to style the wrap container of the static price
   */
  wrapStyle?: SxStyleProp;
  /**
   * The price in pence.
   */
  value: number;
  /**
   * Whether to show the symbol or not.
   * Defaults to true.
   */
  showSymbol?: boolean;
  decimal?: boolean;
  /**
   * Format the price with a strikethrough.
   */
  strikethrough?: boolean;
  /**
   * Translated string for the suffix.
   */
  suffix?: string;
  /**
   * Whether to show plus symbol or not.
   * Defaults to false.
   * */
  showPlus?: boolean;
}

export interface GetPriceParams
  extends Pick<StaticPriceProps, 'value' | 'decimal' | 'showSymbol' | 'showPlus'> {
  currencyFormat: CurrencyFormat;
}

export const getPrice = ({
  value,
  currencyFormat,
  decimal = false,
  showSymbol = true,
  showPlus = false,
}: GetPriceParams) => {
  let price = value;

  price = convertPenceToPounds(price);

  if (!decimal) {
    price = Math.ceil(price);
  }

  const priceToShow = showSymbol ? currencyFormat(price) : price;

  return showPlus && price >= 0 ? `+${priceToShow}` : priceToShow;
};

/**
 * Renders a price based purely on the props passed in.
 */
export const StaticPrice: React.FC<StaticPriceProps> = ({
  'data-id': dataId,
  className,
  priceStyles,
  suffixStyles,
  wrapStyle,
  value,
  decimal,
  strikethrough,
  showSymbol = true,
  suffix,
  showPlus = false,
}) => {
  const { t } = useTranslation();
  const currencyFormat = useCurrency();

  const price = getPrice({
    currencyFormat,
    value,
    decimal,
    showSymbol,
    showPlus,
  });

  const Wrap = strikethrough ? 's' : 'span';

  return (
    <Wrap
      data-id={dataId}
      className={className}
      aria-label={strikethrough ? t('previousPrice', { price }) : undefined}
      sx={wrapStyle}
    >
      <span sx={priceStyles}>{price}</span>
      {suffix && <span sx={suffixStyles}> {suffix}</span>}
    </Wrap>
  );
};
